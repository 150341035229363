import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts';
import 'element-ui/lib/theme-chalk/index.css';
import '@/css/reset.scss'
import '@/css/iconfont.css'
import '@/css/nexticon.css'
import "swiper/swiper.min.css"

import {
  Button, Input, Form, FormItem, Container, Header, Aside, Main, Col, Dropdown, DropdownMenu, DropdownItem, RadioGroup, Radio,
  Row, Menu, Submenu, MenuItemGroup, MenuItem, Progress, Tabs, TabPane, Drawer, Alert, Checkbox, Message, MessageBox, CheckboxGroup,
  Select, Option, DatePicker, InputNumber, Table, TableColumn, CheckboxButton, Cascader, Upload, Steps, Step, Pagination, Switch,
  Dialog, Backtop, Icon
} from 'element-ui'
import "@/css/element-variables.scss"
Vue.use(Button)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Col)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Row)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Progress)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Drawer)
Vue.use(Alert)
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(InputNumber)
Vue.use(Checkbox)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(Cascader)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Upload)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Pagination)
Vue.use(Switch)
Vue.use(Dialog)
Vue.use(Backtop)
Vue.use(Icon)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
