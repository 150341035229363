let plant = [
    {
        path: '/plant',
        name: 'plant',
        component: () => import('@/views/plant/Plant'),
        redirect: '/plant/plan',
        children: [
            {
                path: 'plan',
                name: 'plan',
                component: () => import('@/views/plant/plan/Plan')
            },
        ]
    }
]
export default plant