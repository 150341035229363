<template>
  <div class="login_wrap">
    <img src="https://farm.linyangio.com/imgs/2023/0128/069af9c02d7b23b7aef5b6dbfe97017d.png" alt="" class="img">
    <div class="login_content">
      <div class="login_left">
        <div class="title">数智农场</div>
        <div class="slogan">物联感知，数据精耕，开启云上农场新体验</div>
        <div class="english_slogan">Powered by LinYang Cloud</div>
      </div>
      <div class="login_right">
        <div class="login_img_wrap">
          <img src="../../assets/imgs/logo.jpg" alt="">
        </div>
        <h3>登录</h3>
        <el-form :model="loginForm" ref="loginForm" label-width="100px" class="demo-dynamic">
          <el-form-item prop="account" :rules="[
      { required: true, message: '账号不能为空', trigger: 'blur' }
    ]">
            <el-input v-model="loginForm.account" placeholder="请输入账号" maxlength="10" @input="inputAccount"></el-input>
          </el-form-item>
          <el-form-item prop="password" :rules="{
      required: true, message: '密码不能为空', trigger: 'blur'
    }">
            <el-input type="password" v-model="loginForm.password" placeholder="请输入密码" maxlength="10" @input="inputPassword"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="login_footer">@{{year}} Linyangio.com 版权所有 ICP证：鄂ICP备2021007911号-1</div>
  </div>
</template>

<script>
import { getYear } from "@/utils/common";
import { home } from "@/http/api/index";
export default {
  data() {
    return {
      year: getYear(),
      loginForm: {
        account: "",
        password: "",
      },
    };
  },
  methods: {
    inputAccount() {
      if (this.loginForm.account.length > 10) {
        this.$message({
          message: "最多只能输入 10个字符",
          type: "error",
        });
      }
    },
    inputPassword() {
      if (this.loginForm.password.length > 10) {
        this.$message({
          message: "最多只能输入 10个字符",
          type: "error",
        });
      }
    },
    login() {
      if (!this.loginForm.account) {
        this.$message({
          message: "请输入账号",
          type: "error",
        });
      } else if (!this.loginForm.password) {
        this.$message({
          message: "请输入密码",
          type: "error",
        });
      } else if (!this.loginForm.account && !this.loginForm.password) {
        this.$message({
          message: "请输入账号",
          type: "error",
        });
      }
      if (this.loginForm.account && this.loginForm.password) {
        home("/user/login", this.loginForm).then((res) => {
          console.log(res);
          if (res.code == 0) {
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            this.$router.push("/");
          } else {
            this.$message({
              message: res.message,
              type: "warning",
            });
          }
        });
      }
    },
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.login(); // 需要执行的方法方法
      }
    },
    submitForm() {
      this.login();
    },
  },
  mounted() {
    window.addEventListener("keydown", this.keyDown);
  },
  // 销毁事件
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>

<style scoped lang="scss">
.login_wrap {
  width: 100%;
  height: 100%;
  background-color: #545454;
  position: relative;
  display: flex;
}
::v-deep .el-form-item__label {
  color: #fff;
}
::v-deep .el-form {
  margin: auto;
  background-color: #fff;
}
::v-deep .el-form-item__content {
  margin: 0 !important;
}
::v-deep .el-input__inner {
  border-radius: 0 !important;
}
::v-deep .el-button {
  width: 100%;
}

.img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.login_content {
  z-index: 99;
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: space-around;
  .login_left {
    .title {
      height: 70px;
      width: 100%;
      font-size: 70px;
      color: #fff;
      line-height: 70px;
      text-shadow: 0 2px 4px rgb(65 94 0 / 19%);
    }
    .slogan {
      height: 47px;
      width: 100%;
      font-size: 34px;
      color: #fff;
      text-shadow: 0 2px 4px rgb(0 0 0 / 33%);
      margin-top: 24px;
    }
    .english_slogan {
      height: 47px;
      width: 100%;
      font-size: 34px;
      color: #fff;
      text-shadow: 0 2px 4px rgb(0 0 0 / 33%);
      margin-top: 18px;
    }
  }
  .login_right {
    width: 360px;
    background-color: #fff;
    padding: 30px 40px;
    .login_img_wrap {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      img {
        height: 60px;
      }
    }
    h3 {
      font-size: 24px;
      width: 100%;
      margin: 0 auto;
      color: #666;
      margin-bottom: 25px;
      text-align: center;
      padding-bottom: 10px;
      border-bottom: 1px solid rgb(163, 163, 163);
    }
  }
}
.login_footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 18px 120px;
  background: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  color: rgb(230, 230, 230);
  align-self: flex-end;
}
</style>