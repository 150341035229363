let management = [
    {
        path: 'management',
        name: 'management',
        component: () => import('@/views/management/Management'),
        redirect: "/management/company",
        children: [

            {
                path: 'company',
                name: 'company',
                component: () => import('@/views/management/src/view/Company')
            },
            {
                path: 'news',
                name: 'news',
                component: () => import('@/views/management/src/view/News')
            },
            {
                path: 'journal',
                name: 'journal',
                component: () => import('@/views/management/src/view/Journal')
            },
            {
                path: 'user',
                name: 'user',
                component: () => import('@/views/management/src/view/User')
            },
            {
                path: 'certificate',
                name: 'certificate',
                component: () => import('@/views/management/src/view/Certificate')
            },
            {
                path: 'asset',
                name: 'asset',
                component: () => import('@/views/management/src/view/Asset')
            },
            {
                path: 'honor',
                name: 'honor',
                component: () => import('@/views/management/src/view/Honor')
            },
            {
                path: 'client',
                name: 'client',
                component: () => import('@/views/management/src/view/Client')
            },
        ]
    }
]
export default management