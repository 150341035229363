export function getymd(d) {
    let date = new Date();
    date.setTime(d)
    let year = date.getFullYear()
    let mouth = date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    let time = year + '-' + mouth + '-' + day
    return time
}

export function getYear() {
    let date = new Date();
    let year = date.getFullYear()
    return year
}

export function getTimeNow() {
    let time = new Date();
    let mouth = time.getMonth() < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1
    let day = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
    let hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
    let minute = time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
    let second = time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
    let week = '';
    let date = '';
    const weekInfo = [
        {
            id: 0,
            week: "星期日"
        },
        {
            id: 1,
            week: "星期一"
        },
        {
            id: 2,
            week: "星期二"
        },
        {
            id: 3,
            week: "星期三"
        },
        {
            id: 4,
            week: "星期四"
        },
        {
            id: 5,
            week: "星期五"
        },
        {
            id: 6,
            week: "星期六"
        }
    ]
    return mouth + "月" + day + "日 " + weekInfo.find(i => i.id === time.getDay()).week + " " + hour + ':' + minute + ':' + second + ' ';
}