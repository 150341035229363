import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseInfo: {},
    img: "",
    showBigImg: false,
    loading: true,
    url: "",
  },
  getters: {
  },
  mutations: {
    changeBaseInfo(state, val) {
      state.baseInfo = val
    },
    changeImg(state, val) {
      state.img = val
    },
    changeshowBigImg(state, val) {
      state.showBigImg = val
    },
    changeurl(state, val) {
      state.url = val
    }
  },
  actions: {
  },
  modules: {
  }
})
