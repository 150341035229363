import axios from "axios"
import { Loading } from 'element-ui';
let service = axios.create({
    baseURL: "",
    timeout: 6000
})
let num = 0
let loading
function startLoading() {    //使用Element loading-start 方法
    loading = Loading.service({
        lock: true,
        text: 'Loading……',
        background: 'rgba(0, 0, 0, 0.3)'
    })
}
function endLoading() {    //使用Element loading-close 方法
    loading.close()
}
service.interceptors.request.use(config => {
    num == 0 && startLoading()
    num++
    if (localStorage.getItem('userInfo')) {
        config.headers['token'] = JSON.parse(localStorage.getItem('userInfo')).token
    }
    return config
}, err => {
    return Promise.reject(err)
})

service.interceptors.response.use(response => {
    num--
    num == 0 && endLoading();
    let res = response.data
    localStorage.setItem("messageNum", res.no_read_message)
    return res
}, err => { return Promise.reject(err) })

export default service