import service from '../index'

export function home(url, params = {}) {
    return new Promise((resolve, reject) => {
        service.get(url, { params }).then(res => {
            resolve(res)
        }, err => {
            reject(err)
        })
    })
}