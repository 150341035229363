import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/Login'
import plant from "./plant/index"
import detection from "./detection/index"
import equipment from "./equipment/index"
import weather from "./weather/index"
import soilTilth from "./soilTilth/index"
import pest from "./pest/index"
import seedlingConditionh from "./seedlingCondition/index"
import quotation from "./quotation/index"
import dataDriving from "./dataDriving/index"
import source from "./source/index"
import waterFertilizer from "./waterFertilizer/index"
import management from "./management/index"
Vue.use(VueRouter)
// if (!JSON.parse(localStorage.getItem("userInfo")).master) {
//   management = ""
// }
const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/datascreen',
    name: 'datascreen',
    component: () => import("@/views/datascreen/Datascreen")
  },
  {
    path: '/',
    component: () => import('@/layOut/Home'),
    redirect: '/farm',
    children: [
      {
        path: 'farm',
        name: 'farm',
        component: () => import('@/views/farm/Farm')
      },
      ...plant,
      ...detection,
      ...weather,
      ...soilTilth,
      ...pest,
      ...seedlingConditionh,
      ...quotation,
      ...source,
      ...dataDriving,
      ...waterFertilizer,
      {
        path: 'bigScreen',
        name: 'bigScreen',
      },
      ...management,
    ],
  },
  {
    path: '*',
    name: '/404',
    component: () => import('@/views/notFound/NotFound')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath === "/login") {
    next()
  } else {
    if (localStorage.getItem('userInfo')) {
      next()
    } else {
      next('login')
    }
  }
})
export default router
